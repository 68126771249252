import React from 'react';
import Bumper from './components/Bumper';
import Testimonials from './components/Testimonials';
import Header from './components/Header';
import Process from './components/Process';
import './LandingPage.css';
import WhatYouGet from './components/WhatYouGet';

function LandingPage() {
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
               
            }}
        >
            <div
                className='bumper-bg'
                style={{
                    // // maxWidth: '1920px',
                    // // backgroundColor: '#FCFDFF',
                    backgroundImage: 'url(./fralene_walkins.png)',
                    // backgroundRepeat: 'no-repeat',
                    // backgroundPosition: 'top -100px right -10%',
                    
                    // backgroundAttachment: 'local',

                    maxWidth: 1920,
                }}
            >


                <Header />
                <Bumper />
                 <Testimonials />
                <Process />
                <WhatYouGet />
            </div>


        </div>
    );


}


export default LandingPage;

