import React from "react";
import { useState } from "react";
import { PopupModal } from "react-calendly";


function CTAButton() {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div
        className="cta-button"
        >
            <button
            
                style={{
                    float: 'left',
                    backgroundColor: '#FEB131',
                    border: 'none',
                    // fontSize: 30,
                    padding: '16px 42px 16px 42px',
                    color: '#FCFDFF',
                    borderRadius: 8,
                    fontFamily: 'brandon-grotesque',
                    position: 'relative',
                    zIndex: 10,
                }}
                onClick={() => setIsOpen(true)}
            >
                Schedule a Free Session
            </button>
            <PopupModal
                // pageSettings={props.pageSettings}

                url="https://calendly.com/fralenewalkins/free-coaching-session"

                rootElement={document.getElementById('root')!}
                open={isOpen}
                onModalClose={() => setIsOpen(false)}
            />
        </div>

    );
}


export default CTAButton;