import React from "react";
import CTAButton from "./CTAButton";

function Bumper() {
    return (
        <section style={{
            height: 550,
            display: 'flex',
            flexDirection: 'column',
        }}>

            <p className="bumper-text">I help anxious Christian women <br />move past indecisiveness <br />by taking specific steps <br />towards their goals</p>
            <div
                className='bumper-spacer'

            ></div>


            <CTAButton />


        </section>
    );
}

export default Bumper;