import React from "react";

function Header() {
    return (
        <header style={{
            display: 'flex',
            // padding: 80,
            // paddingTop: 60,
        }}>

            <div style={{ display: 'block' }}>
                <p className='logo-text'>
                    Fralene Walkins
                </p>
                <p className='tagline'>
                    Confidence Coach
                </p>
            </div>
            {/* <div style={{ flex: 1 }}></div>
            <p>My Story</p>
            <p>Contact</p>
            <p>Pricing</p>
            <div style={{ flex: 1 }}></div> */}
        </header>
    )
}





export default Header;