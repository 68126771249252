import React from "react";
import CTAButton from "./CTAButton";

function Process() {
    return (
        <section
            style={{
                backgroundColor: '#383824',
                // marginTop: -100,
                // padding: 80,
                // paddingTop: 50,
                color: '#FCFDFF',
                textAlign: 'left',
            }}
        >
            <h4>My Process</h4>

            <h5>Mindset</h5>
            <p>We do work to address how you think about yourself, God and the world</p>

            <h5>Emotional Healing</h5>
            <p> Emotions drive our behaviour. We attend to them to help you develop a healthy relationship with yourself</p>

            <h5>Behavioural Alignment</h5>
            <p>The only way to get what you want is to actually do what is necessary. Together we'll work on developing the habits that will get you what you want.
            </p>

            <div
                style={{ height: 80 }}
            ></div>
            <CTAButton />
            <div
                style={{ height: 60 }}
            ></div>
        </section>
    )
}

export default Process;