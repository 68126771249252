import React from "react";
import CTAButton from "./CTAButton";


function Testimonials() {
    return (
        <section
        className='testimonials'
            style={{
                backgroundColor: '#FFECD1',
                
            }}
        >
            <h4>Testimonials</h4>
            <div
                style={{
                    display: 'flex',
                    
                }}
            >
                <div style={{
                    marginRight: 70,
                }}>
                    <p>This experience was worth it! With the plan and tools provided, I was able to resolve my issue. If you have an issue you’ve been working on but haven’t been making headway then you should definitely book a session. You’ll be satisfied with this decision. </p>
                    <h6>- Leslie-Ann</h6>
                </div>
                <div style={{
                    marginRight: 70,
                }}>
                    <p>I will describe my experience with Fralene as life-changing. I was able to gain clarity on how my past experiences shaped my beliefs about myself, others, and the world. I’ve experienced improvements in my self-esteem, marriage, and sleep since my session. Prepare to be a brand new you! </p>
                    <h6>- Apple</h6>
                </div>
                <div >
                    <p>I am proud of myself for doing this work. I am pleased with the shifts that I’ve experienced since my session. Now, I’m more energized and less stressed. I find it easier to forgive myself too. All in all, this was a valuable experience.</p>
                    <h6>- SL</h6>
                </div>
            </div>

            <div
                style={{ display: 'flex', justifyContent: 'center'  }}
            >

                <CTAButton />
            </div>

            
        </section>
    )
}



export default Testimonials;