import React from "react";
import CTAButton from "./CTAButton";

function WhatYouGet() {
    return (
        <section>
            <h4>What you get</h4>
            <h5>Weekly Meeting</h5>
            <p>Each week we speak aiming for insights. The insights gained will be the basis of your progress.</p>
            <h5>Any time chat access</h5>
            <p>You are encouraged to reach out via WhatsApp any time. We can chat about any insight you gain or challenge you face.</p>
            <h5>Personalized Action Plan</h5>
            <p>I'll help you build and execute a plan that will take you to your goal</p>
            <h5>RTT Session</h5>
            <p>Rapid Transformational Therapy (RTT) is a core part of what I do. Regression therapy can help us quickly identify the hurt at the core of your anxiety. Once we know, your personalised recording will help you to move past it.</p>

            <div style={{ height: 60 }}></div>
            <CTAButton />
            <div style={{ height: 60 }}></div>
        </section>
    );
}

export default WhatYouGet;